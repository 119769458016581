import React, { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../hooks/userContext";

const STATUS = {
  Error: 0,
  Success: 1,
  NoLogin: 2,
};
function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}
export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [result, setResult] = useState();
  const [status, setStatus] = useState(STATUS.NoLogin);
  const [redirect, setRedirect] = useState(false);
  const user = useContext(UserContext);

  function submitForm(e) {
    e.preventDefault();
    if (!validateEmail(email) && email !== "")
      setEmailError("Please Enter correct email format");
    else if (email === "") setEmailError("Email should not be empty");
    else setEmailError("");

    if (password === "") setPasswordError("Password should not be empty");
    else setPasswordError("");

    if (emailError || passwordError) return false;
    fetch("/portal/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: {
          email,
          password,
        },
      }),
    })
      .then((data) => {
        if (data.ok) {
          setStatus(STATUS.Success);
          setTimeout(() => setRedirect(true), 3000);
        } else setStatus(STATUS.Error);
        return data.json();
      })
      .then((res) => {
        setResult(res.msg);
        user.setUser(res.user);
      });
  }

  function getResult(status) {
    switch (status) {
      case STATUS.Error:
        return (
          <h5 className="bg-danger p-3 text-white rounded mb-2 text-capitalize">
            {result}
          </h5>
        );
      case STATUS.Success:
        return (
          <h5 className="bg-success p-3 text-white rounded mb-2 text-capitalize">
            {result}
          </h5>
        );
      default:
        break;
    }
  }
  function handleEmail(e) {
    if (!validateEmail(e.target.value) && e.target.value !== "")
      setEmailError("Please Enter correct email format");
    else if (e.target.value === "") setEmailError("Email should not be empty");
    else setEmailError("");
    setEmail(e.target.value);
  }
  function handlePassword(e) {
    if (e.target.value === "") setPasswordError("Password should not be empty");
    else setPasswordError("");
    setPassword(e.target.value);
  }
  if (redirect) {
    return <Navigate to="/portal" replace />;
  }
  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-sm-12 col-md-4"></div>
        <div className="col-sm-12 col-md-4">
          <h2 className="mb-3">Login</h2>
          <>{status !== STATUS.NoLogin ? getResult(status) : null}</>
          <form onSubmit={submitForm}>
            <div className="form-group">
              <label htmlFor="usr">Email:</label>
              <input
                type="Email"
                value={email}
                onChange={handleEmail}
                className={
                  emailError ? "form-control border-danger" : "form-control"
                }
                id="usr"
                placeholder="Email"
              />
              <span className="text-danger">{emailError}</span>
            </div>
            <div className="form-group">
              <label htmlFor="pwd">Password:</label>
              <input
                type="password"
                value={password}
                onChange={handlePassword}
                className={
                  passwordError ? "form-control border-danger" : "form-control"
                }
                placeholder="Password"
                id="pwd"
              />
              <span className="text-danger">{passwordError}</span>
            </div>
            <button
              className="btn btn-primary"
              disabled={
                passwordError || emailError || !password || !email
                  ? "disabled"
                  : ""
              }
            >
              Login
            </button>
          </form>
        </div>
        <div className="col-sm-12 col-md-4"></div>
      </div>
    </div>
  );
}
