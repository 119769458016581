import React, { useEffect, useState, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Dealer from "./Dealer";
import "./Map.css";

const DEALERS_NUMBER = 50;
let prevInfoWindow = undefined;
let markers = {
  1460: [],
  1457: [],
  1456: [],
  1463: [],
};

async function fetchDealer() {
  try {
    const result = await fetch("/dealers");
    const dealer = await result.json();
    return dealer;
  } catch (error) {
    return null;
  }
}

async function updateAddress(address) {
  try {
    const response = await fetch(`/address/location/${address.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: address.id,
        lat: address.lat,
        lng: address.lng,
      }),
    });
    await response.json();
    return true;
  } catch (error) {
    return false;
  }
}
function geocodeInfo(geocoder, item, map, markers) {
  const address = `${item.addresses.address1 || ""} ${
    item.addresses.city || ""
  } ${item.addresses.district || ""} ${item.addresses.code || ""}`;
  geocoder.geocode(
    {
      address: address,
    },
    function (results, status) {
      if (status === "OK") {
        const locaiton = results[0].geometry.location;
        addMarker(
          map,
          locaiton,
          item.contacts[0],
          item.addresses,
          item.businessName,
          item.labels,
          markers
        );
        updateAddress({
          id: item.addresses.id,
          lat: locaiton.lat(),
          lng: locaiton.lng(),
        });
      } else if (status === "OVER_QUERY_LIMIT") {
        //setTimeout(() => geocodeInfo(geocoder, item, map), 100);
      }
    }
  );
}
function selectMarkerIcon(label) {
  let image = new window.google.maps.MarkerImage(
    "/img/dark.png",
    new window.google.maps.Size(40, 40),
    new window.google.maps.Point(0, 0),
    new window.google.maps.Point(17, 34),
    new window.google.maps.Size(40, 40)
  );

  switch (label?.id) {
    case 1460:
      image.url = "/img/retail.png";
      break;
    case 1457:
      image.url = "/img/volume.png";
      break;
    case 1456:
      image.url = "/img/trade.png";
      break;
    case 1463:
      image.url = "/img/designer.png";
      break;
    default:
      break;
  }
  return image;
}

function addMarker(map, locaiton, contact, address, name, labels, markers, id) {
  const label = labels?.find(
    (it) => it.id === 1460 || it.id === 1457 || it.id === 1456 || it.id === 1463
  );
  const marker = new window.google.maps.Marker({
    position: locaiton,
    map,
    clickable: true,
    icon: selectMarkerIcon(label),
    optimized: true,
    title: name,
  });
  const infoWindow = addMarkerWindow(contact, address, name, id);
  markers[label?.id]?.push(marker);
  marker.addListener("click", () => {
    if (prevInfoWindow) prevInfoWindow.close();
    infoWindow.open({
      anchor: marker,
      map,
      shouldFocus: true,
    });
    prevInfoWindow = infoWindow;
  });
}

function addMarkerWindow(content, address, name, id) {
  const infoWindow = new window.google.maps.InfoWindow({
    content: `<div class="ml-1 mr-2" id='${name}'>
    <a href="${id}/${address.id}/categories" class="dealer-map-link">
      <h4 class="text-uppercase">${name}</h4>
      <div class="d-flex flex-column my-2">
        <span>${address.address1}</span>
        <span>
          ${address.city} ${address.postalCode}
        </span>
        <span>${address.district}</span>
      </div>
    </a>
    <div class=" btn-group btn-group-justified border rounded">
      <a href='tel:+${content.phone}' class="btn btn-light btn-sm px-3">
        <span>Phone</span>
      </a>
    </div>
  </div>`,
  });

  return infoWindow;
}
function checkNotEmptyAddress(address) {
  return (
    (address.address1 || address.address2) &&
    address.city &&
    address.country &&
    address.district
  );
}

function checkIsLabel(labels, filterLabels) {
  for (let label of labels) {
    for (let flabel of filterLabels) {
      if (label.id === flabel.id) return true;
    }
  }
  return false;
}

function checkNotExcluded(address) {
  if (address.address3 && address.address3.toLowerCase() === "exclude")
    return false;

  return true;
}

export default function Map(props) {
  const ref = useRef(null);
  const [geocoder, setGeocoder] = useState(null);
  const [currentData, setCurrentData] = useState([]);
  const [more, setMore] = useState(false);

  useEffect(() => {
    let uluru = { lat: 42.245, lng: -87.867 };
    setGeocoder(new window.google.maps.Geocoder());
    props.map.setMap(
      new window.google.maps.Map(ref.current, {
        zoom: 4,
        center: uluru,
      })
    );
    // TODO: handle fetch error
    if (props.dealers?.dealers?.length > 0) {
      props.filterDealers.setFilterDealers(props.dealers.dealers);
      setCurrentData(props.dealers.dealers.slice(0, DEALERS_NUMBER));
      if (props.dealers.dealers.length / DEALERS_NUMBER > 1) setMore(true);
      return;
    }
    fetchDealer().then((data) => {
      let filteredData = [];
      for (let dat of data) {
        if (
          !(
            dat.addresses &&
            dat.labels &&
            dat.contacts &&
            dat.businessName &&
            checkIsLabel(dat.labels, props.filters.filters)
          )
        )
          continue;
        for (let add of dat.addresses) {
          if (checkNotExcluded(add) && checkNotEmptyAddress(add))
            filteredData.push({
              id: dat.id,
              displayId: dat.displayId,
              businessName: dat.businessName,
              contacts: dat.contacts,
              labels: dat.labels,
              addresses: add,
            });
        }
      }
      props.dealers.setDealers(filteredData);
      props.filterDealers.setFilterDealers(filteredData);
      setCurrentData(filteredData.slice(0, DEALERS_NUMBER));
      if (filteredData.length / DEALERS_NUMBER > 1) setMore(true);
    });
  }, []);

  useEffect(() => {
    props.filterDealers.filterDealers.forEach((it) => {
      if (it.addresses.length === 0) return;
      if (it.addresses.lat || it.addresses.lng) {
        addMarker(
          props.map.map,
          {
            lat: parseFloat(it.addresses.lat),
            lng: parseFloat(it.addresses.lng),
          },
          it.contacts[0],
          it.addresses,
          it.businessName,
          it.labels,
          markers,
          it.id
        );
      } else {
        geocodeInfo(geocoder, it, props.map.map, markers);
      }
    });
    setCurrentData(props.filterDealers.filterDealers.slice(0, DEALERS_NUMBER));
    if (currentData.length < props.filterDealers.filterDealers.length) {
      setMore(true);
    }
  }, [props.filterDealers.filterDealers]);

  useEffect(() => {
    const checkedFilter = props.filters.filters.filter((la) => !la.state);
    if (checkedFilter.length === 4) {
      checkedFilter.forEach((fil) =>
        markers[fil.id].forEach((mark) => mark.setMap(props.map.map))
      );
      return;
    }
    props.filters.filters.forEach((fil) => {
      if (fil.state)
        markers[fil.id].forEach((mark) => mark.setMap(props.map.map));
      else markers[fil.id].forEach((mark) => mark.setMap(null));
    });
    if (currentData.length < props.filterDealers.filterDealers.length) {
      setMore(true);
    }
  }, [props.filters.filters]);

  function fetchMoreData() {
    if (currentData.length >= props.filterDealers.filterDealers.length) {
      setMore(false);
      return;
    }
    setMore(true);

    setTimeout(() => {
      setCurrentData((prev) =>
        prev.concat(
          props.filterDealers.filterDealers.slice(
            prev.length,
            prev.length - 1 + DEALERS_NUMBER
          )
        )
      );
    }, 500);
  }
  return (
    <div className="col-sm-12 col-md-12 p-0 map-container">
      <div
        ref={ref}
        className={props.showMap.showMap ? "h-100 w-100" : "h-100 w-100 d-none"}
      ></div>

      <InfiniteScroll
        className={
          props.showMap.showMap ? "d-none" : "row mt-5 dealer-list w-100 pl-4"
        }
        dataLength={currentData.length}
        next={fetchMoreData}
        hasMore={more}
        endMessage={
          <div
            className="col-sm-12 my-5 d-flex justify-content-center align-items-center"
            style={{ height: "300px" }}
          >
            {currentData.length === 0 ? (
              <div
                className="spinner-border text-dark"
                style={{ height: "4rem", width: "4rem" }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : null}
          </div>
        }
      >
        {currentData.map((dealer) =>
          dealer.addresses ? (
            <Dealer
              key={dealer.addresses.id}
              name={dealer.businessName}
              addressId={dealer.addresses.id}
              address1={dealer.addresses.address1}
              city={dealer.addresses.city}
              district={dealer.addresses.district}
              postalCode={dealer.addresses.postalCode}
              phone={dealer.contacts[0].phone}
              email={dealer.contacts[0].email}
              id={dealer.id}
            />
          ) : null
        )}
      </InfiniteScroll>
      <button
        className="btn toggle-map shadow-sm border"
        onClick={() => props.showMap.setShowMap(!props.showMap.showMap)}
      >
        {props.showMap.showMap ? "Show as a List" : "Show Map"}
      </button>
    </div>
  );
}
