import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiTwotonePhone } from "react-icons/ai";
import { FaDirections } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import "./TopSectionCat.css";
import { DealerContext } from "../hooks/dealerContext";
async function fetchDealer(dealerId) {
  try {
    const result = await fetch(`/dealers/${dealerId}`);
    const dealer = await result.json();
    return dealer;
  } catch (error) {
    return null;
  }
}
export default function TopSectionCat() {
  const dealer = useContext(DealerContext);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!dealer.dealer) {
      fetchDealer(params.dealer).then((res) => {
        if (!res) navigate("/notfound");
        const address = res.addresses.find(
          (add) => add.id === parseInt(params.addressId)
        );
        if (!address) navigate("/notfound");
        dealer.setDealer({
          id: res.id,
          name: res.businessName,
          addressId: address.id,
          address1: address.address1,
          city: address.city,
          district: address.district,
          postalCode: address.postalCode,
          phone: res.contacts[0].phone,
        });
      });
    }
  }, []);
  if (!dealer.dealer) {
    return (
      <div className="col-sm-12 col-md-12 py-3 px-4 top-section-cat"></div>
    );
  }
  return (
    <div className="col-sm-12 col-md-12 py-3 px-4 top-section-cat border-bottom">
      <div className="row">
        <div className="col-sm-2 d-flex align-items-center">
          <Link
            className="d-flex align-items-center"
            to="/"
            onClick={() => dealer.setDealer(null)}
          >
            <IoMdArrowRoundBack className="mr-1" />
            <span>Back to Home</span>
          </Link>
        </div>
        <div className="col-sm-10 col-md-7 mb-2">
          <h4 className="mt-2">
            <span className="font-weight-normal">Dealer:</span>{" "}
            {dealer.dealer.name}
          </h4>
          <div className="d-flex mt-2">
            <a href={`tel:${dealer.dealer.phone}`} className="actions-btn mr-3">
              <AiTwotonePhone className="m-0 mr-2 action-icon" />
              {dealer.dealer.phone}
            </a>
            <a
              href={`https://www.google.com/maps/dir/?api=1&destination=${
                dealer.dealer.address1
              } ${dealer.dealer.address2 || ""} ${
                dealer.dealer.city
              }&travelmode=driving`}
              target="_blank"
              rel="noreferrer"
              className="actions-btn"
            >
              <FaDirections className="m-0 mr-2 action-icon" />
              Get Directions
            </a>
          </div>
        </div>

        <div className="col-sm-12 col-md-3 d-flex location">
          <div className="pr-3 pt-1 d-flex align-items-center">
            <MdLocationOn className="h3" />
          </div>
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${dealer.dealer.address1} ${dealer.dealer.city}`}
            target="_blank"
            rel="noreferrer"
            className="address"
          >
            <p>{dealer.dealer.address1}</p>
            <p>
              {dealer.dealer.city} {dealer.dealer.postalCode}
            </p>
            <p>{dealer.dealer.district}</p>
          </a>
        </div>
      </div>
    </div>
  );
}
