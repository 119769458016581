import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { DealerContext } from "../hooks/dealerContext";
import { IoMdArrowRoundBack } from "react-icons/io";
import "./Subcategory.css";
async function fetchCategory(id) {
  try {
    const result = await fetch(`/categories/${id}`);
    const category = await result.json();
    return category;
  } catch (error) {
    return [];
  }
}
async function fetchProducts(labelId) {
  try {
    const result = await fetch(`/products/${labelId}`);
    const products = await result.json();
    return products;
  } catch (error) {
    return [];
  }
}
async function fetchFabric(fabric_code) {
  try {
    const result = await fetch(`/products/fabric/${fabric_code}`);
    const fabric = await result.json();
    return fabric;
  } catch (error) {
    return null;
  }
}
function showImg(product) {
  const sku = product.skus.find((sku) => sku.files.length > 0);

  if (sku)
    return (
      <img
        className="w-100 product-img"
        src={sku.files[0].file.signedUrl}
        alt=""
        lazy="true"
      />
    );

  return (
    <img
      className="w-100 img-placeholder"
      src="https://d2wkvvfr7ijdq2.cloudfront.net/jfoWyTQGb-placeholder.jpg"
      alt=""
      lazy="true"
    />
  );
}

function showAvailable(product) {
  const available = product.skus.reduce((prev, curr) => {
    let stocks = 0;
    curr.stockLevels.forEach((stock) => (stocks += stock.available));
    return prev + stocks;
  }, 0);
  return available;
}

function showFabrics(data) {
  data.forEach((it) => {
    const div = document.getElementById(it.id);
    let count = 0;
    it.skus.forEach((sku) => {
      const fabric_code = sku.code.match(/[A-Z]{3,}$/);
      if (fabric_code && fabric_code.length !== 0)
        fetchFabric(fabric_code).then((fabric) => {
          if (!fabric || count > 3) return;
          const img = document.createElement("img");
          img.className = "mr-1";
          img.src = fabric.url;
          img.alt = fabric.code;
          img.style.width = "40px";
          img.style.height = "40px";
          if (count === 3)
            img.src =
              "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Plus_symbol.svg/1200px-Plus_symbol.svg.png";
          div?.appendChild(img);
          count++;
        });
    });
  });
}
export default function Subcategory() {
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState();
  const [filtered, setFiltered] = useState([]);
  const [inStock, setInStock] = useState(false);
  const location = useLocation();
  const dealer = useContext(DealerContext);

  const titleRef = useRef(null);
  const [brands, setBrands] = useState([
    { id: 1476, name: "ATELIER HOME", state: false },
    { id: 1477, name: "BEYOND BORDERS IMPORTS", state: false },
    { id: 1479, name: "BED TECH", state: false },
    { id: 1478, name: "OLD SOUTH", state: false },
    { id: 1480, name: "ROCKFORD TRADING COMPANY", state: false },
    { id: 1906, name: "JES & GRAY", state: false },
    { id: 2400, name: "PADMA'S PLANTATION", state: false },
    { id: 2416, name: "SOUTHERN SKY HOME", state: false },
  ]);
  const params = useParams();

  useEffect(() => {
    fetchCategory(params.id).then((cat) => {
      setCategory(cat);
      fetchProducts(cat.labels[0].id).then((data) => {
        setProducts(data);
        setFiltered(data);
        showFabrics(data);
        titleRef.current.innerText = cat.name;
      });
    });
  }, [location, params.id]);

  useEffect(() => {
    if (brands.filter((br) => !br.state).length === brands.length && !inStock) {
      setFiltered(products);
      return;
    }
    if (brands.filter((br) => !br.state).length === brands.length && inStock) {
      setFiltered(
        products.filter((pro) => {
          const available = showAvailable(pro);
          if (available > 0) return true;
          else return false;
        })
      );
      return;
    }
    setFiltered(
      // eslint-disable-next-line array-callback-return
      products.filter((pro) => {
        const filter = brands.filter((br) => br.state);
        const product = pro.labels.find((label) =>
          filter.find((br) => br.id === label.id)
        );
        if (inStock) {
          const available = showAvailable(pro);
          if (available > 0 && product) return true;
        } else {
          if (product) return true;
          else return false;
        }
      })
    );
  }, [brands, inStock]);

  function changeRoute(id) {
    fetchCategory(id).then((cat) => {
      fetchProducts(cat.labels[0].id).then((data) => {
        setProducts(data);
        setFiltered(data);
        if (titleRef) titleRef.current.innerText = cat.name;
        resetFilter();
      });
    });
  }
  function resetFilter() {
    setBrands(
      brands.map((br) => {
        br.state = false;
        return br;
      })
    );
  }
  function changeBrand(brand) {
    const changed = brands.map((br) => {
      if (br.id === brand.id) br.state = !br.state;
      return br;
    });
    setBrands(changed);
  }

  return (
    <>
      <div className="col-sm-12 col-md-3 pt-4">
        <Link
          to={`/${dealer?.dealer?.id}/${dealer?.dealer?.addressId}/categories`}
          className="d-flex align-items-center"
        >
          <IoMdArrowRoundBack className="mr-2" />
          <span>Back to Categories</span>
        </Link>
        <div className="form-check mt-2">
          <label htmlFor="instock" className="form-check-label">
            <input
              type="checkbox"
              id="instock"
              className="form-check-input"
              onChange={(e) => setInStock(e.target.checked)}
            />
            <span>In Stock only</span>
          </label>
        </div>

        {category ? (
          <>
            {category.nodes.subCategories.length > 0 ? (
              <>
                <h4 className="text-uppercase mb-1">CATEGORY</h4>
                {category.nodes.subCategories.map((cat) => (
                  <h6 key={cat.id} className="text-uppercase">
                    <Link
                      className="text-dark"
                      to={`/${dealer?.dealer?.id}/${dealer?.dealer?.addressId}/categories/${cat.id}`}
                      onClick={() => changeRoute(cat.id)}
                    >
                      {cat.name}
                    </Link>
                  </h6>
                ))}
              </>
            ) : null}
          </>
        ) : null}
        <div className="d-flex justify-content-between align-items-center mb-1 mt-2">
          <h4 className="text-uppercase">Brand</h4>
          <button
            className="btn btn-light btn-sm"
            onClick={resetFilter}
            disabled={brands.some((br) => br.state) ? null : "disabled"}
          >
            Reset Filter
          </button>
        </div>
        {brands.map((brand) => (
          <h6
            key={brand.id}
            className={brand.state ? "brand brand-selected" : "brand"}
            onClick={() => changeBrand(brand)}
          >
            {brand.name}
          </h6>
        ))}
      </div>
      <div className="col-sm-12 col-md-9">
        <div className="row">
          <h1
            className="col-12 text-center text-uppercase mb-3 mt-2"
            ref={titleRef}
          >
            {""}
          </h1>
          {filtered.map((product) =>
            product.skus.length !== 0 ? (
              <div key={product.id} className="col-sm-12 col-md-4 col-lg-3 p-3">
                <div className="border rounded">
                  <Link
                    to={`/${dealer?.dealer?.id}/${dealer?.dealer?.addressId}/product/${product.id}`}
                    className="product-link"
                  >
                    {showImg(product)}
                    <h5 className="text-uppercase product-name my-2 mx-4">
                      {product.name}
                    </h5>
                    <div className="m-2" id={product.id}></div>
                    <h5 className="text-uppercase mb-3 mx-4">
                      {showAvailable(product)} available
                    </h5>
                  </Link>
                </div>
              </div>
            ) : null
          )}
        </div>
      </div>
    </>
  );
}
