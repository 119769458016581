import React, { useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import "./Categories.css";
import Subcategory from "./Subcategory";

async function fetchCategories() {
  try {
    const result = await fetch("/categories");
    const categories = await result.json();
    const data = await Promise.all(
      categories.map((cat) =>
        fetch(`/categories/${cat.id}/image`)
          .then((res) => res.json())
          .then((c) => {
            cat.image = c.image;
            return cat;
          })
      )
    );

    return data;
  } catch (error) {
    return [];
  }
}
export default function Categories() {
  const [categories, setCategories] = useState([]);
  const [subcategory, setSubcategory] = useState();

  useEffect(() => {
    fetchCategories().then((res) => {
      setCategories(res);
    });
  }, []);

  return (
    <Routes>
      <Route
        exact
        path="/:id"
        element={<Subcategory subcategory={subcategory} />}
      />
      <Route
        path="/"
        element={
          <>
            <div className="col-sm-12">
              <h1 className="text-center mb-3">Shop by Category</h1>
            </div>
            {categories.map((cat) => (
              <div key={cat.id} className="col-sm-12 col-md-4 col-lg-3 p-3">
                <Link to={`${cat.id}`} onClick={() => setSubcategory(cat)}>
                  <div className="border rounded-lg">
                    <img className="w-100" lazy="true" src={cat.image} alt="" />
                    <h4 className="category-name text-center text-uppercase mt-2 mb-2">
                      {cat.name}
                    </h4>
                  </div>
                </Link>
              </div>
            ))}
          </>
        }
      />
    </Routes>
  );
}
