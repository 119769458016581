import React, { useState } from "react";
import TopSection from "./TopSection";
import Map from "./Map";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

export default function Main(props) {
  const render = (status) => {
    return (
      <div className="col-sm-12 col-md-12 p-0 d-flex align-items-center justify-content-center map-container">
        <h1 className="text-center">{status}</h1>
      </div>
    );
  };
  const [showMap, setShowMap] = useState(true);
  const [map, setMap] = useState();
  const [filterDealers, setFilterDealers] = useState([]);
  const [filters, setFilters] = useState([
    {
      id: 1460,
      name: "Preferred Partner",
      state: false,
      color: "#495c6f",
    },
    { id: 1457, name: "Premium Dealer", state: false, color: "#759a80" },
    { id: 1456, name: "Dealer", state: false, color: "#778686" },
    {
      id: 1463,
      name: "Design Trade",
      state: false,
      color: "#F1CAA7",
    },
  ]);

  return (
    <div className="container-fluid">
      <div className="row">
        <TopSection
          {...props}
          showMap={showMap}
          map={{ map, setMap }}
          filterDealers={{ filterDealers, setFilterDealers }}
          filters={{ filters, setFilters }}
        />

        <Wrapper
          apiKey={"AIzaSyCFZ_SPrFrhdMcMSe_Algn7kIza5er8--s"}
          render={render}
        >
          <Map
            {...props}
            showMap={{ showMap, setShowMap }}
            map={{ map, setMap }}
            filterDealers={{ filterDealers, setFilterDealers }}
            filters={{ filters, setFilters }}
          />
        </Wrapper>
      </div>
    </div>
  );
}
