import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import "./App.css";
import Main from "./components/Main";
import Header from "./components/Header";
import Category from "./components/Category";
import { DealerContext } from "./hooks/dealerContext";
import { SearchContext } from "./hooks/searchContext";
import Login from "./components/Login";
import Portal from "./components/Portal";
import { UserContext } from "./hooks/userContext";
import Search from "./components/Search";
import NotFound from "./components/NotFound";
import Product from "./components/Product";
import Signup from "./components/Signup";

function App() {
  const [dealers, setDealers] = useState([]);
  const [dealer, setDealer] = useState();
  const [user, setUser] = useState();
  const [searchProducts, setSearchProducts] = useState([]);

  return (
    <DealerContext.Provider value={{ dealer, setDealer }}>
      <UserContext.Provider value={{ user, setUser }}>
        <SearchContext.Provider value={{ searchProducts, setSearchProducts }}>
          <div className="App">
            <Header setSearchProducts={setSearchProducts} />
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route
                path="/"
                element={<Main dealers={{ dealers, setDealers }} />}
              />
              <Route path="/login/" element={<Login />} />
              <Route path="/signup/" element={<Signup />} />
              <Route path="/portal" element={<Portal />} />
              <Route
                path="/:dealer/:addressId/categories/*"
                element={<Category />}
              />
              <Route
                path="/:dealer/:addressId/product/:id"
                element={<Product />}
              />
              <Route
                path="/:dealer/:addressId/search"
                element={
                  <Search
                    searchProducts={{ searchProducts, setSearchProducts }}
                  />
                }
              />
            </Routes>
          </div>
        </SearchContext.Provider>
      </UserContext.Provider>
    </DealerContext.Provider>
  );
}

export default App;
