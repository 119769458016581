import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { UserContext } from "../hooks/userContext";
import { IoMdArrowRoundBack } from "react-icons/io";

import "./Portal.css";

const STATUS = {
  Error: 0,
  Success: 1,
  NoLogin: 2,
};

async function checkLogin() {
  try {
    const result = await fetch(`/portal/checklogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const status = await result.json();
    return status;
  } catch (error) {
    return null;
  }
}

async function fetchDealerUpdateLogs() {
  try {
    const result = await fetch(`/update_dealer/logs`);
    const status = await result.json();
    return status;
  } catch (error) {
    return null;
  }
}
async function fetchDealerUpdate() {
  try {
    const result = await fetch(`/update_dealer`);
    const status = await result.json();
    return status;
  } catch (error) {
    return null;
  }
}
export default function Portal() {
  const [result, setResult] = useState(STATUS.NoLogin);
  const [dealerUpdate, setDealerUpdate] = useState([]);
  const [addressUpdate, setAddressUpdate] = useState([]);
  const [contactUpdate, setContactUpdate] = useState([]);
  const [labelUpdate, setLabelUpdate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState();
  const user = useContext(UserContext);

  function updateDealers() {
    setLoading(true);
    fetchDealerUpdate().then((data) => {
      if (!data) return;
      changeState(data);
      setLoading(false);
    });
  }

  function changeState(data) {
    let count =
      data.dealer_status.delete.count +
      data.dealer_status.new.count +
      data.dealer_status.update.count;
    count +=
      data.address_status.delete.count +
      data.address_status.new.count +
      data.address_status.update.count;
    count +=
      data.contact_status.delete.count +
      data.contact_status.new.count +
      data.contact_status.update.count;
    count +=
      data.label_status.delete.count +
      data.label_status.new.count +
      data.label_status.update.count;
    setUpdate(count);
    let dealer = [];
    dealer = dealer.concat(data.dealer_status.delete.logs);
    dealer = dealer.concat(data.dealer_status.new.logs);
    dealer = dealer.concat(data.dealer_status.update.logs);
    let address = [];
    address = address.concat(data.address_status.delete.logs);
    address = address.concat(data.address_status.new.logs);
    address = address.concat(data.address_status.update.logs);
    let contact = [];
    contact = contact.concat(data.contact_status.delete.logs);
    contact = contact.concat(data.contact_status.new.logs);
    contact = contact.concat(data.contact_status.update.logs);
    let label = [];
    label = label.concat(data.label_status.delete.logs);
    label = label.concat(data.label_status.new.logs);
    label = label.concat(data.label_status.update.logs);
    setDealerUpdate(dealer);
    setAddressUpdate(address);
    setContactUpdate(contact);
    setLabelUpdate(label);
  }
  useEffect(() => {
    checkLogin().then((data) => {
      if (!data.status) {
        setResult(STATUS.Error);
        return;
      }
      user.setUser(data.user);
      setResult(STATUS.Success);
    });

    fetchDealerUpdateLogs().then((data) => {
      if (!data) return;
      changeState(data);
    });
  }, []);
  if (result === STATUS.Error) {
    return <Navigate to="/login" />;
  }
  if (result === STATUS.NoLogin) return null;
  return (
    <div className="container-fluid">
      <div className="row pt-4">
        <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
          <Link to="/">
            <IoMdArrowRoundBack className="mr-1" />
            <span>Back to stores</span>
          </Link>
          <h3 className="mt-2">Update:</h3>
          <p className="mt-3">Update your Dealer List</p>
          {loading ? <div>this may take couple minites</div> : null}
          <button
            className="btn btn-success mt-3"
            onClick={updateDealers}
            disabled={loading ? "disabled" : null}
          >
            {loading ? <div className="loader"></div> : <span>Update</span>}
          </button>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-8 mb-3">
          <h3>Result</h3>
          <p className="mb-4">
            {update > 0
              ? `${update} Recent Changes`
              : "There is no recent changes"}
          </p>
          {update !== 0 ? (
            <>
              <h5 className="mb-2">{dealerUpdate.length} Dealer Changes</h5>
              <div className="changes">
                {dealerUpdate.map((dealerLog) => (
                  <p key={dealerLog}>{dealerLog}</p>
                ))}
              </div>
              <h5 className="mb-2 mt-3">
                {addressUpdate.length} Address Changes
              </h5>
              <div className="changes">
                {addressUpdate.map((addressLog) => (
                  <p key={addressLog}>{addressLog}</p>
                ))}
              </div>
              <h5 className="mb-2 mt-3">
                {contactUpdate.length} Contact Changes
              </h5>
              <div className="changes">
                {contactUpdate.map((contactLog) => (
                  <p key={contactLog}>{contactLog}</p>
                ))}
              </div>
              <h5 className="mb-2 mt-3">{labelUpdate.length} Label changes</h5>
              <div className="changes">
                {labelUpdate.map((labelLog) => (
                  <p key={labelLog}>{labelLog}</p>
                ))}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
