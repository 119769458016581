import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { UserContext } from "../hooks/userContext";
import { DealerContext } from "../hooks/dealerContext";
import "./header.css";
import SearchInput from "./SearchInput";
export default function Header(props) {
  const user = useContext(UserContext);
  const dealer = useContext(DealerContext);
  const [result, setResult] = useState("");
  function logout() {
    fetch("/portal/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          setResult(data.msg);
          setTimeout(() => {
            user.setUser(null);
            setResult("");
            window.location.replace("/");
          }, 2000);
        }
      });
  }

  return (
    <div className="header">
      <Link to="/" onClick={() => dealer.setDealer(null)}>
        <img className="logo" src="/img/logo.png" alt="rockford logo" />
      </Link>
      {result ? (
        <h5 className="bg-success p-3 text-white rounded text-capitalize">
          {result}
        </h5>
      ) : null}
      {dealer.dealer ? <SearchInput {...props} /> : null}
      {user.user ? (
        <div className="d-flex align-items-center">
          <Link to="/portal" title="Go to Portal page" className="user-name">
            {user.user.name}
          </Link>
          <button
            className="btn btn-sm btn-danger ml-3 d-flex align-items-center justify-content-center"
            title="Logout"
            onClick={logout}
          >
            <BiLogOut className="h5 m-0" />
          </button>
        </div>
      ) : null}
    </div>
  );
}
