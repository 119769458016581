import React, { useEffect, useState } from "react";
import "./TopSection.css";
import { BiSearch } from "react-icons/bi";

export default function TopSection(props) {
  const [search, setSearch] = useState("");

  const updateDebounce = debounce((text) => {
    setSearch(text);
  });
  function debounce(cb, delay = 1500) {
    let timeout;

    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  function handleSearch(e) {
    updateDebounce(e.target.value);
  }

  function searchAddress(addresses) {
    return addresses.fullAddress.toLowerCase().includes(search.toLowerCase());
  }
  function searchContact(contacts) {
    return contacts.some(
      (cont) =>
        cont.phone.toLowerCase().includes(search.toLowerCase()) ||
        cont.name.toLowerCase().includes(search.toLowerCase())
    );
  }
  function searchLabel(labels) {
    const checkedFilter = props.filters.filters.filter((la) => la.state);
    if (checkedFilter.length === 0) return true;
    for (let label of labels) {
      for (let filLabel of checkedFilter) {
        if (label.id === filLabel.id) return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (window.google) {
      const geocoder = new window.google.maps.Geocoder();
      if (search)
        geocoder.geocode(
          {
            address: search,
          },
          function (results, status) {
            if (status === "OK") {
              props.map.map.setCenter(results[0].geometry.location);
              props.map.map.setZoom(10);
            } else {
              console.log(status);
            }
          }
        );
    }
    props.filterDealers.setFilterDealers(
      props.dealers.dealers.filter(
        (deal) =>
          searchLabel(deal.labels) &&
          (deal.businessName.toLowerCase().includes(search.toLowerCase()) ||
            searchAddress(deal.addresses) ||
            searchContact(deal.contacts))
      )
    );
  }, [search, props.filters.filters]);
  function changeFilter(filter) {
    const changed = props.filters.filters.map((br) => {
      if (br.id === filter.id) br.state = !br.state;
      return br;
    });
    props.filters.setFilters(changed);
  }
  return (
    <div className="col-sm-12 col-md-12 pt-3 pb-2 px-4 border-bottom top-section">
      <div className="row">
        <div className="col-sm-12 col-md-6 mb-2">
          <h5 className="mb-2">Search for a Dealer</h5>
          <label className="w-100 border rounded py-1 px-2 d-flex align-items-center bg-white">
            <BiSearch />
            <input
              className="border-0 ml-2 w-100"
              type="text"
              placeholder="Search Location"
              onChange={handleSearch}
            />
          </label>
        </div>
        <div className="col-sm-12 col-md-6 d-flex align-items-center flex-wrap mb-2">
          <span className="mr-2 h4">Filters:</span>
          {props.filters.filters.map((fil) => (
            <button
              style={{
                border: `3px solid ${fil.color}`,
                background: fil.state ? `${fil.color}` : "white",
                color: fil.state ? "white" : "black",
              }}
              key={fil.id}
              className="button-border filter-btn mr-2"
              onClick={() => changeFilter(fil)}
            >
              {fil.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
