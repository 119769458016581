import React, { useState, useEffect, createRef } from "react";
import { IoMdClose } from "react-icons/io";
import "./SlideShow.css";

export default function SlideShow(props) {
  const [refs] = useState(props.images.map(() => createRef()));
  const modalImg = createRef(null);
  const modalDiv = createRef(null);
  let timeout;
  let slideIndex = 0;

  function nextSlide() {
    slideIndex += 1;
    showSlides();
  }

  function prevSlide() {
    slideIndex -= 1;
    showSlides();
  }

  function currentSlide(n) {
    slideIndex = n;
    showSlides();
  }

  function showSlides() {
    if (!window.location.pathname.includes("product")) return;
    refs.forEach((ref) => (ref.current.style.display = "none"));
    if (slideIndex >= refs.length) slideIndex = 0;
    if (slideIndex < 0) slideIndex = refs.length - 1;
    refs[slideIndex].current.style.display = "block";
    clearTimeout(timeout);
    timeout = setTimeout(nextSlide, 10000);
  }

  function toggleModal() {
    modalDiv.current.classList.toggle("show");
  }
  function showImage(index) {
    toggleModal();
    modalImg.current.src = refs[index].current.children[0].src;
  }

  useEffect(() => {
    showSlides();
  }, []);
  return (
    <>
      <div className="modal" ref={modalDiv}>
        <div className="modal-dialog modal-lg  modal-dialog-centered">
          <div className="modal-content bg-transparent border-0  ">
            <button
              type="button"
              className="close close-button"
              data-dismiss="modal"
              onClick={toggleModal}
            >
              <IoMdClose className="text-white" />
            </button>
            <img src="" alt="" ref={modalImg} className="mb-2 modal-img" />
          </div>
        </div>
      </div>
      <div className="slideshow-container">
        {props.images.map((image, i) => (
          <div key={i} ref={refs[i]} className="mySlides">
            <img
              onClick={() => showImage(i)}
              src={image.signedUrl}
              alt={image.code}
              className="w-100 slide-img"
            />
            <div className="text">{image.code}</div>
          </div>
        ))}

        <a className="prev" onClick={() => prevSlide()}>
          &#10094;
        </a>
        <a className="next" onClick={() => nextSlide()}>
          &#10095;
        </a>
      </div>
      <div className="min-viewer">
        {props.images.map((image, i) => (
          <span key={i} className="min-img" onClick={() => currentSlide(i)}>
            <img className="min-img" src={image.signedUrl} alt={image.code} />
          </span>
        ))}
      </div>
    </>
  );
}
