import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { GrSelect } from "react-icons/gr";
import { DealerContext } from "../hooks/dealerContext";
export default function Dealer(props) {
  const dealer = useContext(DealerContext);

  const selectDealer = () => {
    dealer.setDealer({
      id: props.id,
      name: props.name,
      addressId: props.addressId,
      address1: props.address1,
      city: props.city,
      district: props.district,
      postalCode: props.postalCode,
      phone: props.phone,
      email: props.email,
    });
  };
  return (
    <div className="col-sm-12 col-md-6 col-lg-4 px-1 px-md-2 mt-4 dealer">
      <div className="border px-3 px-md-3 pt-3 pb-3">
        <Link
          to={`${props.id}/${props.addressId}/categories`}
          onClick={selectDealer}
        >
          <h4 className="text-uppercase">{props.name}</h4>
          <div className="d-flex flex-column my-2">
            <span>{props.address1}</span>
            <span>
              {props.city} {props.postalCode}
            </span>
            <span>{props.district}</span>
          </div>
        </Link>
        <div className=" btn-group btn-group-justified border rounded">
          <Link
            to={`${props.id}/${props.addressId}/categories`}
            onClick={selectDealer}
            className="btn btn-main btn-sm px-3"
          >
            <GrSelect className="h6 m-0" />
            <span className="ml-2">Select Dealer</span>
          </Link>
          <a href={`tel:${props.phone}`} className="btn btn-light btn-sm px-3">
            <FaPhoneAlt />
            <span className="ml-2">Phone</span>
          </a>
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${props.address1} ${props.city}`}
            className="btn btn-light btn-sm px-3"
            target="_blank"
            rel="noreferrer"
          >
            <MdLocationOn className="h5 m-0" />
            <span className="ml-2">Location</span>
          </a>
        </div>
      </div>
    </div>
  );
}
