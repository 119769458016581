import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import TopSectionCat from "./TopSectionCat";
import SlideShow from "./SlideShow";
import { DealerContext } from "../hooks/dealerContext";
import { IoMdArrowRoundBack } from "react-icons/io";
import Email from "./Email";

async function fetchProductData(id) {
  try {
    const result = await fetch(`/products/product/${id}`);
    const product = await result.json();
    return product;
  } catch (error) {
    return null;
  }
}
async function fetchFabric(fabric_code) {
  try {
    const result = await fetch(`/products/fabric/${fabric_code}`);
    const fabric = await result.json();
    return fabric;
  } catch (error) {
    return null;
  }
}
export default function Product() {
  const [product, setProduct] = useState();
  const [images, setImages] = useState([]);
  const [cbf, setCbf] = useState();
  const [dimenions, setDimenions] = useState();
  const [skuId, setSkuId] = useState();
  const dealer = useContext(DealerContext);
  const params = useParams();

  useEffect(() => {
    fetchProductData(params.id).then((res) => {
      setProduct(res);
      let images = [];

      res.skus.forEach((sku) => {
        const fabric_code = sku.code.match(/[A-Z]{3,}$/);
        if (fabric_code && fabric_code.length !== 0)
          fetchFabric(fabric_code).then((fabric) => {
            if (!fabric) return;
            const td = document.getElementById(`fabric-${sku.id}`);

            td.innerHTML = `<img style="width:100px" src="${fabric.url}" alt="${fabric.code}" />`;
          });
        sku.files.forEach((file) =>
          images.push({ signedUrl: file.file.signedUrl, code: sku.code })
        );
      });

      setImages(images);
      const cbf_res = res.customFields.find((cf) => cf.id === 53);
      const dim_res = res.customFields.find((cf) => cf.id === 127);
      setCbf(cbf_res ? "CBF:" + cbf_res.value : "");
      setDimenions(dim_res ? "DIMENSIONS: " + dim_res.value : "");
    });
  }, [params.id]);

  return (
    <div className="container-fluid">
      <div
        className="modal fade"
        style={{ overflow: "auto" }}
        id="email-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {skuId && <Email sku={{ skuId, setSkuId }} />}
          </div>
        </div>
      </div>
      <div className="row">
        <TopSectionCat />
        <div className="col-sm-12 col-md-12 py-4 category-list">
          {product ? (
            <div className="row">
              <div className="col-12">
                <Link
                  to={`/${dealer?.dealer?.id}/${dealer?.dealer?.addressId}/categories`}
                  className="d-flex align-items-center"
                >
                  <IoMdArrowRoundBack className="mr-2" />
                  <span>Back to Categories</span>
                </Link>
              </div>
              <div className="col-sm-12 col-md-6">
                {images.length > 0 ? <SlideShow images={images} /> : null}
                <p className="text-center mt-2">{cbf}</p>
                <p className="text-center">{dimenions}</p>
              </div>
              <div className="col-sm-12 col-md-6">
                <h2 className="mb-3">{product?.name}</h2>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>FINISH</th>
                        <th>PRODUCT</th>
                        <th>SKU</th>
                        <th>AVAILABLE</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {product?.skus.map((sku) => (
                        <tr key={sku.id}>
                          <td id={`fabric-${sku.id}`}></td>
                          <td>{sku.name}</td>
                          <td>{sku.code}</td>
                          <td>
                            {sku.stockLevels.reduce(
                              (prev, curr) => prev + curr.available,
                              0
                            )}
                          </td>
                          <td>
                            <button
                              className="btn btn-main"
                              onClick={() => {
                                const emailModal =
                                  document.getElementById("email-modal");
                                emailModal.classList.toggle("show");
                                setSkuId(sku.id);
                              }}
                            >
                              Get More Information
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <p>{product?.description}</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
