import React, { useContext, useRef } from "react";
import { BiSearch } from "react-icons/bi";
import "./SearchInput.css";
import { useNavigate } from "react-router-dom";
import { DealerContext } from "../hooks/dealerContext";
import { SearchContext } from "../hooks/searchContext";

export default function SearchInput(props) {
  const dealer = useContext(DealerContext);
  const search = useContext(SearchContext);
  const searchRef = useRef();
  const navigate = useNavigate();

  function handleSearch(e) {
    e.preventDefault();
    navigate(
      `${dealer.dealer.id}/${dealer.dealer.addressId}/search?text=${searchRef.current.value}`
    );
    fetch(`/products/search/${searchRef.current.value}`)
      .then((res) => res.json())
      .then((data) => {
        search.setSearchProducts(data);
      });
  }
  return (
    <form onSubmit={handleSearch}>
      <label className="w-100 border rounded py-1 px-2 d-flex align-items-center">
        <button type="submit" className="btn btn-sm">
          <BiSearch />
        </button>
        <input
          className="border-0 ml-2 w-100 search"
          type="text"
          placeholder="Search for product"
          ref={searchRef}
        />
      </label>
    </form>
  );
}
