import React from "react";
import TopSectionCat from "./TopSectionCat";
import Categories from "./Categories";

export default function category() {
  return (
    <div className="container-fluid">
      <div className="row">
        <TopSectionCat />
        <div className="col-sm-12 col-md-12 py-4 category-list">
          <div className="row">
            <Categories />
          </div>
        </div>
      </div>
    </div>
  );
}
