import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { DealerContext } from "../hooks/dealerContext";

import "./Email.css";
async function fetchProductData(id) {
  try {
    const result = await fetch(`/products/product/${id}`);
    const product = await result.json();
    return product;
  } catch (error) {
    return null;
  }
}
export default function Email(props) {
  // const [product, setProduct] = useState();
  const [sku, setSku] = useState();
  const dealer = useContext(DealerContext);
  const params = useParams();
  const textareaRef = useRef();
  const emailRef = useRef();
  const nameRef = useRef();
  const phoneRef = useRef();
  const [textareaError, setTextareaError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [result, setResult] = useState("");
  const [status, setStatus] = useState(false);
  const resultRef = useRef();

  async function sendEmail() {
    if (!textareaRef.current.value) setTextareaError("Please write your email");
    else setTextareaError("");
    if (!emailRef.current.value) setEmailError("Email should not be empty");
    else setEmailError("");
    if (!nameRef.current.value) setNameError("Name should not be empty");
    else setNameError("");

    if (textareaError || emailError || nameError) return;
    let content = textareaRef.current.value;
    content += "\n" + nameRef.current.value;
    content += "\n" + emailRef.current.value;

    if (phoneRef.current.value) content += "\nPhone: " + phoneRef.current.value;
    content = content.replaceAll("\n", "<br>");
    const response = await fetch("/email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        content: content,
        dealerId: dealer.dealer.id,
      }),
    });
    const result = await response.json();
    if (result.status) setStatus(true);
    else setStatus(false);
    setResult(result.msg);
    setTimeout(() => {
      resultRef.current.style.opacity = 0;
      setTimeout(() => setResult(""), 1000);
    }, 5000);
  }
  useEffect(() => {
    fetchProductData(params.id).then((res) => {
      // setProduct(res);
      setSku(res.skus.find((sk) => sk.id === props.sku.skuId));
    });
  }, [props, params.id]);
  useEffect(() => {
    if (sku)
      textareaRef.current.value = `Hello,\n\nThis product, ${sku.name}  (SKU: ${sku.code}), interests me.\nI would appreciate any additional details you could provide,\nincluding pricing and availability.\n\nThank you!`;
  }, [sku]);
  return (
    <div className="container-fluid">
      <div className="row">
        {result ? (
          <div
            ref={resultRef}
            className={status ? "result bg-success" : "result bg-danger"}
          >
            <span>{result}</span>
            <AiOutlineClose />
          </div>
        ) : null}
        <div className="col-sm-12 mb-4">
          <div className="row mt-3">
            <div className="col-9">
              <h4>
                Send an Email:
                <p className="small">
                  Please complete your email details below.
                </p>
              </h4>
            </div>
            <div className="col-3 d-flex align-items-center justify-content-end">
              <button
                type="button"
                onClick={() => {
                  const emailModal = document.getElementById("email-modal");
                  emailModal.classList.toggle("show");
                  props.sku.setSkuId(null);
                }}
                className="btn btn-danger btn-sm"
                data-dismiss="modal"
                aria-label="Close"
              >
                <AiOutlineClose />
              </button>
            </div>
            <div className="col-sm-12 col-md-8 ">
              <textarea
                ref={textareaRef}
                className={
                  textareaError
                    ? "border rounded w-100 mt-3 p-3 email-input lead border-danger"
                    : "border rounded w-100 mt-3 p-3 email-input lead"
                }
                rows="4"
                placeholder="Write your Email here"
                style={{ resize: "none", height: "250px" }}
              ></textarea>
              <span className="text-danger">{textareaError}</span>
            </div>
            <div className="col-sm-12 col-md-4 pt-4 d-flex flex-column justify-content-center align-items-center">
              <img
                className="border mb-3"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
                src={sku?.files[0]?.file?.signedUrl}
                alt={sku?.name}
              />
              <h5 className="text-center">{sku?.name}</h5>
              <h6 className="text-center">SKU: {sku?.code}</h6>
            </div>
            <div className="col-sm-12 col-md-6 mt-3">
              <h4>Full Name:</h4>
              <input
                ref={nameRef}
                type="text"
                className={
                  nameError
                    ? "form-control w-100 email-input mt-3 border-danger"
                    : "form-control w-100 email-input mt-3"
                }
                placeholder="Full Name"
              />
              <span className="text-danger">{nameError}</span>
            </div>
            <div className="col-sm-12 col-md-6 mt-3">
              <h4>Email:</h4>
              <input
                ref={emailRef}
                type="email"
                className={
                  emailError
                    ? "form-control w-100  email-input mt-3 border-danger"
                    : "form-control w-100 email-input mt-3"
                }
                placeholder="Email"
              />
              <span className="text-danger">{emailError}</span>
            </div>
            <div className="col-sm-12 col-md-6 mt-3">
              <h4>Phone:</h4>
              <input
                ref={phoneRef}
                type="text"
                className="form-control w-100 email-input mt-3"
                placeholder="Phone Number (optional)"
              />
            </div>
            <div className="col-sm-12 mt-4 mb-1">
              <button
                onClick={sendEmail}
                className="btn btn-main text-uppercase"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
