import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { DealerContext } from "../hooks/dealerContext";
import { SearchContext } from "../hooks/searchContext";
import TopSectionCat from "./TopSectionCat";
async function fetchProducts(text) {
  try {
    const result = await fetch(`/products/search/${text}`);
    const products = await result.json();
    return products;
  } catch (error) {
    return [];
  }
}
async function fetchFabric(fabric_code) {
  try {
    const result = await fetch(`/products/fabric/${fabric_code}`);
    const fabric = await result.json();
    return fabric;
  } catch (error) {
    return null;
  }
}
function showImg(product) {
  const sku = product.skus.find((sku) => sku.files.length > 0);

  if (sku)
    return (
      <img
        className="w-100 product-img"
        src={sku.files[0].file.signedUrl}
        alt=""
        lazy="true"
      />
    );

  return (
    <img
      className="w-100 img-placeholder"
      src="https://d2wkvvfr7ijdq2.cloudfront.net/jfoWyTQGb-placeholder.jpg"
      alt=""
      lazy="true"
    />
  );
}

function showAvailable(product) {
  const available = product.skus.reduce((prev, curr) => {
    let stocks = 0;
    curr.stockLevels.forEach((stock) => (stocks += stock.available));
    return prev + stocks;
  }, 0);
  return available;
}
function showFabrics(data) {
  data.forEach((it) => {
    const div = document.getElementById(it.id);
    let count = 0;
    it.skus.forEach((sku) => {
      const fabric_code = sku.code.match(/[A-Z]{3,}$/);
      if (fabric_code && fabric_code.length !== 0)
        fetchFabric(fabric_code).then((fabric) => {
          if (!fabric || count > 3) return;
          const img = document.createElement("img");
          img.className = "mr-1";
          img.src = fabric.url;
          img.alt = fabric.code;
          img.style.width = "40px";
          img.style.height = "40px";
          if (count === 3)
            img.src =
              "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Plus_symbol.svg/1200px-Plus_symbol.svg.png";
          div?.appendChild(img);
          count++;
        });
    });
  });
}
export default function Search(props) {
  const [products, setProducts] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [inStock, setInStock] = useState(false);
  const [title, setTitle] = useState("");
  const [params, setParams] = useSearchParams();
  const location = useLocation();
  const dealer = useContext(DealerContext);
  const search = useContext(SearchContext);

  const [brands, setBrands] = useState([
    { id: 1476, name: "ATELIER HOME", state: false },
    { id: 1477, name: "BEYOND BORDERS IMPORTS", state: false },
    { id: 1479, name: "BED TECH", state: false },
    { id: 1478, name: "OLD SOUTH", state: false },
    { id: 1480, name: "ROCKFORD TRADING COMPANY", state: false },
  ]);

  useEffect(() => {
    if (search.searchProducts.length > 0) {
      setProducts(search.searchProducts);
      setFiltered(search.searchProducts);
      return;
    }
    fetchProducts(params.get("text")).then((data) => {
      setTitle(`Search: ${params.get("text")}`);
      setProducts(data);
      setFiltered(data);
      showFabrics(data);
    });
  }, []);

  useEffect(() => {
    setProducts(search.searchProducts);
    setFiltered(search.searchProducts);
  }, [search]);
  useEffect(() => {
    fetchProducts(params.get("text")).then((data) => {
      setTitle(`Search: ${params.get("text")}`);
      setProducts(data);
      setFiltered(data);
      showFabrics(data);
    });
  }, [location]);
  useEffect(() => {
    if (brands.filter((br) => !br.state).length === 5 && !inStock) {
      setFiltered(products);
      return;
    }
    if (brands.filter((br) => !br.state).length === 5 && inStock) {
      setFiltered(
        products.filter((pro) => {
          const available = showAvailable(pro);
          if (available > 0) return true;
          else return false;
        })
      );
      return;
    }
    setFiltered(
      products.filter((pro) => {
        const filter = brands.filter((br) => br.state);
        const product = pro.labels.find((label) =>
          filter.find((br) => br.id === label.id)
        );

        if (inStock) {
          const available = showAvailable(pro);
          if (available > 0 && product) return true;
        } else {
          return product;
        }
      })
    );
  }, [brands, inStock]);

  function resetFilter() {
    setBrands(
      brands.map((br) => {
        br.state = false;
        return br;
      })
    );
  }
  function changeBrand(brand) {
    const changed = brands.map((br) => {
      if (br.id === brand.id) br.state = !br.state;
      return br;
    });
    setBrands(changed);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <TopSectionCat />
        <div className="col-sm-12 col-md-12 py-4 category-list">
          <div className="row">
            <div className="col-sm-12 col-md-3 pt-4">
              <div className="form-check">
                <label htmlFor="instock" className="form-check-label">
                  <input
                    type="checkbox"
                    id="instock"
                    className="form-check-input"
                    onChange={(e) => setInStock(e.target.checked)}
                  />
                  <span>In Stock only</span>
                </label>
              </div>

              <div className="d-flex justify-content-between align-items-center mb-1 mt-2">
                <h4 className="text-uppercase">Brand</h4>
                <button className="btn btn-light btn-sm" onClick={resetFilter}>
                  Reset
                </button>
              </div>
              {brands.map((brand) => (
                <h6
                  key={brand.id}
                  className={brand.state ? "brand brand-selected" : "brand"}
                  onClick={() => changeBrand(brand)}
                >
                  {brand.name}
                </h6>
              ))}
            </div>
            <div className="col-sm-12 col-md-9">
              <div className="row">
                <h1 className="col-12 text-center text-uppercase mb-3 mt-2">
                  {title}
                </h1>
                {filtered.map((product) =>
                  product.skus.length !== 0 ? (
                    <div
                      key={product.name}
                      className="col-sm-12 col-md-4 col-lg-3 p-3"
                    >
                      <div className="border rounded">
                        <Link
                          to={`/${dealer.dealer.id}/${dealer?.dealer?.addressId}/product/${product.id}`}
                          className="product-link"
                        >
                          {showImg(product)}
                          <h5 className="text-uppercase product-name my-2 mx-4">
                            {product.name}
                          </h5>
                          <div className="m-2" id={product.id}></div>
                          <h5 className="text-uppercase mb-2 mx-4">
                            {showAvailable(product)} available
                          </h5>
                        </Link>
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
