import React from "react";
import { BiError } from "react-icons/bi";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="container">
      <div className="row">
        <div
          className="col-12 d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <div>
            <div className="text-center text-warning">
              <BiError style={{ fontSize: "10rem" }} />
            </div>
            <div
              className="text-center"
              style={{ fontSize: "10rem", lineHeight: 0.9 }}
            >
              404
            </div>
            <div className="text-center display-4">Page Not Found</div>
            <div className="text-center">
              <Link to="/" className="btn btn-primary mt-3">
                Go Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
